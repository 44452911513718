.github {
	@apply font-primary;
	@apply gray-600;
	@apply dark:gray-400;
}

.github h1,
h2,
h3,
h4,
h5,
h6,
p {
	@apply block;
	@apply leading-relaxed;
}

.github h1 {
	@apply text-3xl;
	@apply font-bold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply pb-4;
	@apply mb-4;
	@apply border-b border-gray-400 dark:border-gray-600;
	@apply border-opacity-25 dark:border-opacity-25;
}

.github h2 {
	@apply text-2xl;
	@apply font-semibold;
	@apply gray-800;
	@apply dark:gray-200;
	@apply pb-2;
	@apply mb-4;
	@apply border-b border-gray-400 dark:border-gray-600;
	@apply border-opacity-25 dark:border-opacity-25;
}

.github h3 {
	@apply text-xl;
	@apply font-medium;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.github h4 {
	@apply text-lg;
	@apply font-medium;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.github h5 {
	@apply text-base;
	@apply font-medium;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.github h6 {
	@apply text-base;
	@apply font-medium;
	@apply gray-800;
	@apply dark:gray-200;
	@apply mb-4;
}

.github p {
	@apply text-base;
	@apply font-normal;
	@apply font-secondary;
	@apply leading-5;
	@apply gray-600;
	@apply dark:gray-400;
	@apply mb-4;
}

.github small {
	@apply text-xs;
	@apply font-normal;
	@apply font-secondary;
	@apply leading-6;
	@apply gray-500;
	@apply dark:gray-500;
	@apply mb-4;
}

.github a {
	@apply inline-block;
	@apply font-normal;
	@apply font-secondary;
	@apply leading-6;
	@apply blue-500 h:blue-400  dark:cyan-500 dark:h:cyan-400;
	@apply underline;
}

.github ol {
	@apply ml-4 pl-4 pb-4;
}

.github ol li {
	list-style-type: auto;
}

.github ul {
	@apply ml-4 pl-4 pb-4;
}

.github ul li {
	list-style-type: disc;
}

.github li {
	@apply pl-2;
	@apply mb-0.5;
}

.github hr {
	@apply mt-4;
	@apply pb-4;
	@apply border-gray-200 dark:border-gray-700;
}

.github pre {
	@apply gray-600 dark:gray-300;
	@apply text-sm;
	@apply mb-4;
}

.github pre code {
	@apply block;
	@apply w-full;
	@apply px-4 py-3;
}

.github pre span {
	@apply leading-6;
}

.github code {
	@apply bg-gray-100 dark:bg-gray-800;
	@apply border-opacity-25;
	@apply gray-600 dark:gray-300;
	@apply inline;
	@apply px-1 py-0.5;
	@apply rounded-md;
	@apply text-sm;
}

.github table {
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
	@apply w-full;
}

.github table th {
	@apply p-2;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
}

.github table td {
	@apply p-2;
	@apply border;
	@apply border-gray-200 dark:border-gray-700;
}